import { pageEvents } from 'tracking';
import * as Sentry from '@sentry/nextjs';
import Cookie from 'js-cookie';
import type { WebStore } from 'types/webStore';
import type { Product } from 'types/torii';
import { trackCustomVariable } from 'tracking/helpers';
import { getToriiProductCategory } from 'tracking/utils';
import { Locale } from 'types/locale';
import { getAirgap } from 'utils/airgap/getAirgap';
import { evaluateAccessToken } from 'utils/helpers/userCookies';

export const LANG_PROMPT_LOCALES = ['ch', 'be'];

let setupComplete = typeof window === 'undefined';

export function requiresLanguagePrompt(locale: string, country: string) {
  if (!locale) {
    Sentry.captureMessage('Site was loaded with unknown locale params');
    return false;
  }

  const languagePref = Cookie.get('v1-lang-preference');
  const isMultiLanguageCountry = LANG_PROMPT_LOCALES.includes(country.toLowerCase());
  // if lang pref exist, check if it matches the current country
  if (languagePref) {
    const hasMadeLanguageChoice = languagePref.includes(country.toLowerCase());
    return isMultiLanguageCountry && !hasMadeLanguageChoice;
  }

  return isMultiLanguageCountry;
}

type Props = {
  pageName: string;
  webStore: WebStore;
  product?: Product;
  locale: Locale;
  relativePath?: string;
  params: {
    locale: string;
  };
  messages: any;
  target: any;
};

export function setup(props: Props) {
  if (setupComplete) {
    return;
  }

  try {
    getAirgap(); // Loads Airgap, loads GTM when ready
    const userId = Cookie.get('userId') as number;

    if (userId) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userId.toString() });
      });
    }

    if (props.locale?.prefix) {
      Sentry.configureScope(function (scope) {
        scope.setTag('page_locale', `${props.locale.prefix}`);
      });

      hotjarCleanup();

      // not setting the preference if we're waiting for a country to be selected
      if (
        !window.location.hash.includes('country-select') &&
        !requiresLanguagePrompt(props.locale.prefix, props.webStore?.country?.iso)
      ) {
        Cookie.set('v1-lang-preference', props.locale.prefix, { expires: 365 });
      }
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
  }

  setupComplete = true;
}

export async function trackPageView(props: Props) {
  const airgap = await getAirgap();

  if (!airgap) {
    console.error('airgap not initialized, failed to send pageview.');
  }
  if (props.pageName === 'profile') return; // profile events handled within component itself due to client side navigation

  const hasValidAccessToken = !!evaluateAccessToken();

  const userId = Cookie.get('userId') as number;
  const emailHash = Cookie.get('emailHash');
  const fullPath = window.location.pathname;

  trackCustomVariable(1, 'pageName', props.pageName ?? 'unknown');
  trackCustomVariable(2, 'country', props.webStore?.country?.iso);
  trackCustomVariable(3, 'userLoginState', hasValidAccessToken ? 'logged-in' : 'logged-out');
  if (props.product) {
    trackCustomVariable(
      4,
      'productCategory',
      getToriiProductCategory(props.product.currentVariant, props.product.productType)
    );
  }

  pageEvents.pageView({
    country: props.webStore?.country?.iso,
    currency: props.webStore?.config?.currency,
    hashM: emailHash,
    hashM256: emailHash,
    pageName: props.pageName,
    relativePath: props.relativePath || fullPath,
    userId,
    userLoginState: hasValidAccessToken ? 'logged-in' : 'logged-out'
  });
}

// temporary script to cleanup hotjar cookies
const hotjarCookies = [
  '_hjAbsoluteSessionInProgress',
  '_hjDonePolls',
  '_hjSessionUser_701055',
  '_hjTLDTest',
  '_hjSession_701055',
  '_hjFirstSeen',
  '_hjid',
  '_hjIncludedInPageviewSample',
  '_hjIncludedInSessionSample'
];

function hotjarCleanup() {
  try {
    hotjarCookies.forEach(cookie => {
      Cookie.set(cookie, '', {
        expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
        path: '/',
        domain: '.aceandtate.com'
      });
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
}
