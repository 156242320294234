import React from 'react';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import styled from 'styled-components';
import { grid } from '@aceandtate/ds';

const StyledContainer = styled.div`
  width: 100%;
  background: #eee;
  padding: ${grid[16]};
`;

export default function Block(props) {
  const data = useContentfulLiveUpdates(props);

  return (
    <StyledContainer>
      <div>Name: {data.internalName}</div>
      <div>Current Viewport: {data.viewport}</div>
    </StyledContainer>
  );
}
