import { defineMessages } from 'react-intl';

const messages = defineMessages({
  askForPrescriptionLater: {
    defaultMessage: 'You can add your prescription during checkout or after your purchase.',
    id: 'cart.askForPrescriptionLater'
  },
  cartHtoTitle: {
    defaultMessage: 'Home Try-On',
    id: 'cart.shoppingCartHto'
  },
  cartPageTitle: {
    defaultMessage: 'Prescription glasses online from €98 | Ace & Tate',
    id: 'cartPage.pageTitle'
  },
  duplicateItem: {
    defaultMessage: 'Duplicate',
    id: 'cart.item.duplicate'
  },
  emptyCartMessage: {
    defaultMessage: 'Still waiting to be filled with the good stuff',
    id: 'cart.emptyMessage'
  },
  empty: {
    defaultMessage: 'Looks like your cart is empty',
    id: 'cart.empty'
  },
  errorDialogOk: {
    defaultMessage: 'Ok',
    id: 'cart.errorDialogOk'
  },
  findAFrame: {
    defaultMessage: 'Find a frame',
    id: 'cart.findAFrame'
  },
  fiveDaysTryOn: {
    defaultMessage: '5 days to try-on.',
    id: 'priceDetails.fiveDaysTryon'
  },
  free: {
    defaultMessage: 'Free',
    id: 'priceDetails.free'
  },
  giftCardCode: {
    defaultMessage: 'I have a gift card or code',
    id: 'cart.giftCardCode'
  },
  homeTryOn: {
    defaultMessage: 'Home Try-on',
    id: 'cart.homeTryOn'
  },
  homeTryOnPitch: {
    defaultMessage: `Pick up to 4 of your favourite frames (no prescription
      included) and try them on for 5 days - it's on us!`,
    id: 'cart.homeTryOnPitch'
  },
  htoMaxOrder: {
    defaultMessage: 'You can order four Home try-on items at a time. Please remove items from your cart.',
    id: 'cart.HtoMaxOrder'
  },
  htoWarning: {
    defaultMessage:
      'Please note that you cannot order home try-on products and regular items at the same time. One of these will be removed when you continue to checkout.',
    id: 'cart.HtoWarning'
  },
  index_174: {
    defaultMessage: '1.74 lenses',
    id: 'cart.ultraThinLensesIndex'
  },
  myCart: {
    defaultMessage: 'My cart',
    id: 'cart.myCart'
  },
  orderHomeTryOn: {
    defaultMessage: 'order home try-on',
    id: 'priceDetails.orderHomeTryOn'
  },
  orderSummaryTitle: {
    defaultMessage: 'Order Summary',
    id: 'cart.orderSummaryTitle'
  },
  outOfStock: {
    defaultMessage: 'this product is currently out of stock.',
    id: 'cart.item.outOfStock'
  },
  placeOrder: {
    defaultMessage: 'Place order',
    id: 'cart.placeOrder'
  },
  productRemovedSnackbar: {
    defaultMessage: 'This product was removed from your cart.',
    id: 'cart.productRemovedSnackbar'
  },
  recommendedProducts: {
    defaultMessage: 'Recommended products',
    id: 'cart.recommendedProducts'
  },
  removeItem: {
    defaultMessage: 'Remove',
    id: 'cart.item.removeItem'
  },

  shipping: {
    defaultMessage: 'Shipping',
    id: 'priceDetails.shipping'
  },
  shoppingCart: {
    defaultMessage: 'Shopping cart',
    id: 'cartPage.shoppingCart'
  },
  someHtoProductsOutOfStock: {
    defaultMessage:
      'One or more of your selected Home-Try-On frames are out of stock. Please remove them or select another frame before continuing to checkout.',
    id: 'cart.someHtoProductsOutOfStock'
  },
  subtotalInclVAT: {
    defaultMessage: 'Subtotal (incl. VAT)',
    id: 'priceDetails.subtotalInclVAT'
  },
  totalInclVAT: {
    defaultMessage: 'Total (incl. VAT)',
    id: 'priceDetails.totalInclVAT'
  },
  undo: {
    defaultMessage: 'undo',
    id: 'cart.undo'
  },
  yourOrderMessage: {
    defaultMessage: 'Your order',
    id: 'cart.YourOrder'
  },
  checkout: {
    defaultMessage: 'Checkout',
    id: 'cart.checkout'
  }
});

export default messages;
