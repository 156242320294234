import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Typography, Button, Modal, InputControl, TextInput, Checkbox, Flex, grid } from '@aceandtate/ds';
import { useSubscribe } from 'services/newsletterService';
import { useSubscribeToStockMailingList } from 'services/stockMailingListSubscriptionService';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { stockEvents } from 'tracking';
import newsletterFormMessages from 'messages/newsletterForm';
import CheckboxWithConditionalChildren, { StyledPrivacyPolicyLink } from 'components/CheckboxWithConditionalChildren';
import messages from './messages';
import * as Styles from './styles';
import { paths } from 'paths';
import authenticationMessages from 'messages/authentication';
import ecommerceMessages from 'messages/ecommerce';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  sku: string;
};

type Status = 'default' | 'submit' | 'complete';
type FormData = {
  email: string;
  privacyPolicy: boolean;
  dateOfBirth: string;
  subscriptionNewsletter: boolean;
  gender: string;
};

export default function OOSModal(props: Props) {
  const { onClose, sku, isOpen } = props;
  const [status, setStatus] = useState<Status>('default');

  const subscribeToStockMailingList = useSubscribeToStockMailingList();
  const subscribeToNewsLetter = useSubscribe();
  const validationOptions = useValidationOptions();

  const hookForm = useForm<FormData>({ mode: 'onChange' });
  const { control, register, handleSubmit, formState, setError } = hookForm;
  const { errors } = formState;

  async function onSubmit(data: FormData) {
    try {
      const requests = [];
      setStatus('submit');
      requests.push(subscribeToStockMailingList({ email: data.email, sku }));
      stockEvents.backInStockFormSubmitted({ sku });

      if (data.subscriptionNewsletter) {
        requests.push(
          subscribeToNewsLetter({
            email: data.email,
            date_of_birth: data.dateOfBirth,
            formType: 'OOS modal',
            gender: data.gender
          })
        );
      }

      await Promise.all(requests);
      setStatus('complete');
    } catch (error) {
      setError('email', { message: error.message });
      setStatus('default');
    }
  }

  function renderValidation(key: string) {
    return errors?.[key] && <InputControl.Validation status='error'>{errors[key].message}</InputControl.Validation>;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      content={
        <>
          <Typography variant='h4' gutterBottom>
            {status !== 'complete' && <FormattedMessage {...messages.oosModalTitle} />}
            {status === 'complete' && <FormattedMessage {...messages.oosModalSuccessTitle} />}
          </Typography>
          <Typography variant='bodyM' gutterBottom>
            {status !== 'complete' && <FormattedMessage {...messages.oosModalBody} />}
            {status === 'complete' && <FormattedMessage {...messages.oosModalSuccessBody} />}
          </Typography>
          {status !== 'complete' && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Styles.FormGroup>
                <Styles.StyledInputControl id='oosModal-email' required>
                  <InputControl.Label>
                    <FormattedMessage {...messages.emailLabel} />
                  </InputControl.Label>
                  <TextInput
                    {...register('email', { ...validationOptions.emailValidation })}
                    type='email'
                    fullWidth
                    placeholder='john@doe.com'
                    data-testid='oos-modal'
                  />
                  {renderValidation('email')}
                </Styles.StyledInputControl>

                <Controller
                  name='privacyPolicy'
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <Styles.StyledInputControl id='privacyPolicy'>
                      <Checkbox
                        checked={value}
                        onCheckedChange={onChange}
                        id='privacy-policy'
                        label={
                          <Typography variant='bodyS'>
                            <FormattedMessage
                              {...authenticationMessages.privacyPolicyConsent}
                              values={{
                                privacyPolicy: (
                                  <StyledPrivacyPolicyLink
                                    href={paths.privacyPolicy}
                                    target='blank'
                                    rel='noreferrer noopener'
                                  >
                                    <FormattedMessage {...authenticationMessages.privacyPolicy} />
                                  </StyledPrivacyPolicyLink>
                                )
                              }}
                            />
                          </Typography>
                        }
                      />
                      {renderValidation(`privacyPolicy`)}
                    </Styles.StyledInputControl>
                  )}
                  rules={validationOptions.requiredField}
                  control={control}
                />

                <InputControl id='subscriptionNewsletter'>
                  <Controller
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxWithConditionalChildren
                        checked={value}
                        onChange={onChange}
                        id='subscription-newsletter'
                        label={
                          <Typography variant='bodyS'>
                            <FormattedMessage {...newsletterFormMessages.subscribeToNewsletter} />
                          </Typography>
                        }
                      >
                        <Flex gap={grid[12]} flexWrap='wrap'>
                          <FormProvider {...hookForm}>
                            <CheckboxWithConditionalChildren.Gender />
                          </FormProvider>
                          <CheckboxWithConditionalChildren.DateOfBirth {...register('dateOfBirth')} fullWidth />
                        </Flex>
                      </CheckboxWithConditionalChildren>
                    )}
                    name='subscriptionNewsletter'
                    control={control}
                  />
                </InputControl>
              </Styles.FormGroup>
              <Button color='primary' fullWidth disabled={!formState.isValid} loading={status === 'submit'}>
                <FormattedMessage {...messages.oosModalCta} />
              </Button>
            </form>
          )}
          {status === 'complete' && (
            <Button color='primary' onClick={onClose} style={{ width: 'fit-content' }}>
              <FormattedMessage {...ecommerceMessages.close} />
            </Button>
          )}
        </>
      }
    />
  );
}
