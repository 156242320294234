import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import { Checkbox, Flex, InputControl, TextInput, grid } from '@aceandtate/ds';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import formLabelMessages from 'messages/formLabels';
import ValidatedSelect from 'components/ValidatedSelect';
import { ChangeHandler } from 'react-hook-form';
export const StyledPrivacyPolicyLink = styled(Link)`
  text-decoration: underline;
`;

type CheckboxWithConditionalChildrenProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  dataTestId?: string;
  id?: string;
  fullWidth?: boolean;
};

type DateOfBirthProps = {
  fullWidth?: boolean;
  name?: string;
  onBlur?: ChangeHandler;
  onChange?: ChangeHandler;
};

function CheckboxWithConditionalChildren({
  checked,
  onChange,
  label,
  children,
  style,
  id,
  dataTestId = 'checkbox-with-children'
}: CheckboxWithConditionalChildrenProps) {
  return (
    <Flex flexDirection='column' style={style} gap={grid[12]}>
      <Checkbox checked={checked} onCheckedChange={onChange} data-testid={dataTestId} label={label} id={id} />
      {checked && children}
    </Flex>
  );
}

const DateOfBirth = React.forwardRef<HTMLInputElement, DateOfBirthProps>((props, ref) => {
  const { fullWidth } = props;
  return (
    <InputControl id='dob' style={{ flexBasis: '200px', flexGrow: 400 }}>
      <InputControl.Label>
        <FormattedMessage {...formLabelMessages.dateOfBirth} /> (<FormattedMessage {...formLabelMessages.optional} />)
      </InputControl.Label>
      <InputMask {...props} mask='99-99-9999' maskChar='_'>
        {
          (({ ...inputProps }) => (
            <TextInput placeholder='DD-MM-YYYY' {...inputProps} ref={ref} fullWidth={fullWidth} />
          )) as any
        }
      </InputMask>
    </InputControl>
  );
});

const Gender = React.forwardRef<HTMLInputElement, { name?: string }>(props => {
  const intl = useIntl();
  const items = [
    {
      id: 'male',
      value: 'male',
      label: intl.formatMessage(formLabelMessages.menPreference)
    },
    {
      id: 'female',
      value: 'female',
      label: intl.formatMessage(formLabelMessages.womenPreference)
    },
    {
      id: 'noPreference',
      value: null,
      label: intl.formatMessage(formLabelMessages.noPreference)
    }
  ];

  return (
    <ValidatedSelect
      placeholder={intl.formatMessage(formLabelMessages.selectPreference)}
      label={
        <>
          <FormattedMessage {...formLabelMessages.shoppingPreference} /> (
          <FormattedMessage {...formLabelMessages.optional} />)
        </>
      }
      id='gender'
      name={props.name || 'gender'}
      style={{ flexBasis: '200px', flexGrow: 400 }}
    >
      {items.map(item => (
        <ValidatedSelect.Item key={item.id} label={item.label} value={item.value}>
          {item.label}
        </ValidatedSelect.Item>
      ))}
    </ValidatedSelect>
  );
});

export default Object.assign(CheckboxWithConditionalChildren, {
  DateOfBirth,
  Gender
});
