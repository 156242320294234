import { captureException } from '@sentry/nextjs';
import { trackGaEvent } from '../helpers';
import { getPageInfo } from '../utils';
import posthog from 'posthog-js';

type PageViewData = {
  country: string;
  currency: string;
  hashM: string;
  hashM256: string;
  relativePath: string;
  userId: number;
  userLoginState: 'logged-in' | 'logged-out';
  pageName?: string;
};

let counter = 1;

export function pageView(pageViewData: PageViewData) {
  const { relativePath, pageName, userLoginState } = pageViewData;
  let formattedPath = relativePath;

  try {
    formattedPath = relativePath.replace(/[?#].*$/, '');
  } catch (err) {
    captureException('Could not format pagename', err);
  }
  const pageInfo = getPageInfo(formattedPath, pageName);

  posthog.capture('$pageview', {
    userLoginState,
    ...pageInfo
  });

  trackGaEvent({
    event: 'page',
    hitType: 'pageview',
    pageCounter: counter++,
    ...pageViewData,
    ...pageInfo
  });
}
