import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputControl, TextInput, Typography, Button, grid, Flex } from '@aceandtate/ds';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useSubscribe } from 'services/newsletterService';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import authenticationMessages from 'messages/authentication';
import newsletterFormMessages from 'messages/newsletterForm';
import * as Styles from './styles';
import CheckboxWithConditionalChildren, { StyledPrivacyPolicyLink } from 'components/CheckboxWithConditionalChildren';
import { paths } from 'paths';
enum Status {
  Error = 'error',
  Loading = 'loading',
  Submitted = 'submitted'
}

type FormData = { email: string; privacyPolicy: boolean; dateOfBirth: string; gender: string };

export default function NewsletterForm({ formType }: { formType: string }) {
  const [status, setStatus] = useState<Status>(null);

  const intl = useIntl();
  const validationOptions = useValidationOptions();
  const subscribeToNewsLetter = useSubscribe();
  const hookForm = useForm<FormData>({ mode: 'onSubmit' });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = hookForm;

  async function handleNewsletterSubscribe(data: FormData) {
    const { success } = await subscribeToNewsLetter({
      email: data.email,
      date_of_birth: data.dateOfBirth,
      gender: data.gender,
      formType
    });

    if (success) {
      setStatus(Status.Submitted);
    } else {
      setStatus(Status.Error);
    }
  }

  function renderValidation(key: string) {
    return errors?.[key] && <InputControl.Validation status='error'>{errors[key].message}</InputControl.Validation>;
  }

  return (
    <>
      {status === Status.Submitted && (
        <Typography variant='h6' as='span' data-testid={`newsletter.${formType}.successMessage`}>
          <FormattedMessage {...newsletterFormMessages.successMessage} />
        </Typography>
      )}
      {status === Status.Error && (
        <Typography color='error'>
          <FormattedMessage {...newsletterFormMessages.errorMessage} />
        </Typography>
      )}
      {!status && (
        <form onSubmit={handleSubmit(handleNewsletterSubscribe)} noValidate>
          <Styles.FormGroup>
            <InputControl id={`newsletter-${formType}-email`} required>
              <InputControl.Label isHidden>
                <FormattedMessage {...newsletterFormMessages.emailAddress} />
              </InputControl.Label>
              <TextInput
                {...register(`email`, { ...validationOptions.emailValidation })}
                type='email'
                fullWidth
                loading={isSubmitting}
                placeholder={intl.formatMessage(newsletterFormMessages.emailPlaceholder)}
                data-testid={`newsletter-${formType}`}
              />
              {renderValidation(`email`)}
            </InputControl>
          </Styles.FormGroup>
          <InputControl id={'newsletterPrivacyPolicy'}>
            <Controller
              render={({ field }) => (
                <CheckboxWithConditionalChildren
                  checked={field.value}
                  onChange={field.onChange}
                  label={
                    <Typography variant='bodyS'>
                      <FormattedMessage
                        {...authenticationMessages.privacyPolicyConsent}
                        values={{
                          privacyPolicy: (
                            <StyledPrivacyPolicyLink
                              href={paths.privacyPolicy}
                              target='blank'
                              rel='noreferrer noopener'
                            >
                              <FormattedMessage {...authenticationMessages.privacyPolicy} />
                            </StyledPrivacyPolicyLink>
                          )
                        }}
                      />
                    </Typography>
                  }
                >
                  <Flex gap={grid[12]} flexWrap='wrap'>
                    <FormProvider {...hookForm}>
                      <CheckboxWithConditionalChildren.Gender />
                    </FormProvider>
                    <CheckboxWithConditionalChildren.DateOfBirth {...register('dateOfBirth')} fullWidth />
                  </Flex>
                </CheckboxWithConditionalChildren>
              )}
              name='privacyPolicy'
              rules={validationOptions.requiredField}
              control={control}
            />
            {renderValidation(`privacyPolicy`)}
          </InputControl>
          <Button type='submit' style={{ marginTop: grid[16] }}>
            <FormattedMessage {...newsletterFormMessages.signUp} />
          </Button>
        </form>
      )}
    </>
  );
}
