import * as Sentry from '@sentry/nextjs';
import { Line } from 'types/solidus';
import { isSunny } from 'utils/product/productHelpers';
import type { ProductVariant, Variant } from 'types/torii';
import { CustomizationOptions, Customization, validateProductConfiguration } from 'services/orderLineConfigurator';
import type { FeCustomizationOptions, MappedExtraCustomizationProps, MappedLensCustomizationProps } from './types';
import type { FrameExtraConfiguration, FrameLensConfiguration } from 'utils/frameConfigurations/types';
import { Locale } from 'types/locale';

// Currently a customer can't configure both a single vision and a
// multifocal prescription in the cart, so this function will
// return false if the selected prescription doesn't align
// with the prescription in cart
export function isValidPrescriptionCombinationWithCart(prescription: string, currentCart: any, isEdit?: boolean) {
  const { regular_lines, item_count } = currentCart;

  if (isEdit && item_count < 2) {
    return true;
  }

  if (!regular_lines || !prescription || ['plano', '0', 'bluelight-filter'].includes(prescription)) {
    return true;
  }

  const cartPrescription = regular_lines
    .filter((item: Line) => item.product_type === 'frame')
    .map((item: Line) => item.prescription_type)
    .find((type: string) => type !== 'plano');

  if (prescription === 'relax_5_lenses' && cartPrescription === 'single_vision') {
    return true;
  }

  return !cartPrescription || cartPrescription === prescription;
}

export function isProductVariantType(product: Variant | ProductVariant): product is ProductVariant {
  return !!(product as ProductVariant).availability;
}

/** Maps to both OLC and Solidus */
export function getPrescriptionType(prescriptionId: string): PrescriptionType {
  if (prescriptionId === 'relax_5_lenses') {
    return 'single_vision';
  }
  if (prescriptionId === 'bluelight-filter') {
    return 'plano';
  }

  return prescriptionId as PrescriptionType;
}

export function getCartLensProductionType(prescriptionId: string): null | 'relax_5_lenses' {
  if (prescriptionId === 'relax_5_lenses') {
    return prescriptionId;
  }

  return null;
}

function getOlcLensProductionType(prescriptionId: string): 'standard' | 'relax_5_lenses' {
  if (prescriptionId === 'relax_5_lenses') {
    return prescriptionId;
  }

  return 'standard';
}

type PrescriptionType = null | 'single_vision' | 'multifocal' | 'plano';

export async function fetchCustomizationsByPrescriptionId(
  prescriptionId: string,
  variant: ProductVariant,
  variantConfiguration,
  locale: Locale
) {
  if (prescriptionId === 'bluelight-filter') {
    return {
      extras: [],
      lenses: []
    };
  }

  try {
    const prescriptionType = getPrescriptionType(prescriptionId);
    const lensProductionType = getOlcLensProductionType(prescriptionId);

    const req = validateProductConfiguration(
      {
        'lens-production-type': lensProductionType,
        'prescription-type': prescriptionType,
        'product-description': variant.sku
      },
      locale
    );

    const response = await req;

    if (response.data.customizations?.length > 1) {
      const backendCustoms = response.data.customizations
        .filter(
          result =>
            result.whenAvailable && ['service_sds', 'in_store_production'].every(key => key !== result.customizationKey)
        )
        .sort(result => (['premium_plus_lenses'].includes(result.customizationKey) ? -1 : 1))
        .sort(result =>
          ['sunny_pro_lenses', 'premium_basic_lenses', 'premium_lenses'].includes(result.customizationKey) ? -1 : 1
        );

      const hasOtherLenses = backendCustoms.some(c =>
        ['sunny_pro_lenses', 'premium_basic_lenses', 'premium_plus_lenses', 'premium_lenses'].includes(
          c.customizationKey
        )
      );

      const showEssentialLenses =
        hasOtherLenses &&
        (['multifocal', 'single_vision'].includes(prescriptionType) ||
          (prescriptionType === 'plano' && isSunny(variant)));

      const frontendCustoms: Customization<FeCustomizationOptions>[] = showEssentialLenses
        ? [
            {
              customizationKey: isSunny(variant) ? 'sunny_essential_lenses' : 'essential_lenses',
              priceDetails: {
                currency: 'EUR',
                display: '€ 0',
                value: 0
              },
              whenAvailable: {
                isSelected: false,
                validationUrl: ''
              }
            }
          ]
        : [];

      const availableCustoms = [...frontendCustoms, ...backendCustoms];

      const mapCustomizationsWithConfig = (
        customizations: Customization<CustomizationOptions | FeCustomizationOptions>[],
        configs: (FrameLensConfiguration | FrameExtraConfiguration)[]
      ) => {
        return customizations
          .map(customization => {
            const configToMerge = configs.find(config => config.id === customization.customizationKey);

            if (!configToMerge) return undefined;

            return {
              ...customization,
              ...configToMerge
            };
          })
          .filter(Boolean);
      };

      const customizationsLensesWithConfig = mapCustomizationsWithConfig(availableCustoms, variantConfiguration.lenses);

      const customizationsLensExtrasWithConfig = mapCustomizationsWithConfig(
        availableCustoms,
        variantConfiguration.extras
      );

      return {
        extras: customizationsLensExtrasWithConfig as MappedExtraCustomizationProps[],
        lenses: customizationsLensesWithConfig as MappedLensCustomizationProps[]
      };
    }
  } catch (err) {
    console.error(err);
    Sentry.captureException(err, {
      extra: {
        message: 'An unexpected error occurred when fetching the customizations in the product configurator'
      }
    });
  }
}
