import posthog from 'posthog-js';

export default function initializeTrackingScripts() {
  // This loads GTM script
  try {
    const gtm = document.createElement('script');
    gtm.innerHTML = `try {
         (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        } catch (err) {
          console.error('Failure in GTM script tag.', err);
        }`;

    document.documentElement.appendChild(gtm);
  } catch (err) {
    console.error('Failed to attach GTM to dom.', err);
  }

  try {
    if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ENABLE_POSTHOG === 'true') {
      // checks that we are client-side
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        ui_host: process.env.NEXT_PUBLIC_POSTHOG_UI_HOST || 'https://eu.i.posthog.com',
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://res.aceandtate.com',
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        loaded: posthog => {
          if (process.env.NEXT_PUBLIC_IS_PRODUCTION !== 'true') posthog.debug(); // debug mode in development and show env
        },
        capture_pageview: false,
        session_recording: {
          maskTextSelector: '[data-cs-mask]'
        }
      });
    }
  } catch (err) {
    console.error('Failed to initialize postHog', err);
  }
}
