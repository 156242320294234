import { InputControl, Select, Typography, grid } from '@aceandtate/ds';
import React, { Children, HTMLAttributes, isValidElement, useMemo } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import { ArrayElement } from 'type-fest/source/internal';
import { SelectItem } from '@aceandtate/ds/dist/components/Select/Select';

type Props = {
  label: React.ReactNode;
  placeholder?: string;
  id: string;
  defaultValue?: any;
  disabled?: boolean;
  children: React.ReactNode;
  options?: RegisterOptions;
  dataTestid?: string;
  name: string;
  initialSelectPosition?: string | number;
  onChange?: (val: string | number) => void;
  style?: React.CSSProperties;
};

const ValidatedSelect = (props: Props) => {
  const {
    id,
    label,
    placeholder,
    defaultValue,
    children,
    dataTestid = null,
    onChange,
    options = {},
    name,
    disabled,
    style = {},
    ...restProps
  } = props;

  const hookForm = useFormContext();
  const {
    formState: { errors },
    control
  } = hookForm;

  const items = useMemo(
    () =>
      Children.toArray(children)
        .filter(child => isValidElement(child) && typeof child !== 'string')
        .map(child => {
          const props: ItemProps = typeof child === 'object' && 'props' in child && child.props;

          return {
            id: props.id || props.value,
            label: props.label,
            renderValueAs: <StyledOption data-value={props.value}>{props.children}</StyledOption>,
            value: props.value
          };
        }),
    [children]
  );

  return (
    <InputControl id={id} required={!!options.required} style={style}>
      <InputControl.Label>{label}</InputControl.Label>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={options}
        shouldUnregister
        render={({ field }) => {
          return (
            <Select
              disabled={disabled}
              selectedItems={items.find(item => item.value === field.value)}
              fullWidth
              items={items}
              renderItem={(item: ArrayElement<typeof items>) => item.renderValueAs}
              renderValue={(item: ArrayElement<typeof items>) => item.renderValueAs}
              placeholder={placeholder || label}
              data-testid={dataTestid}
              setSelectedItems={(val: SelectItem<any>) => {
                field.onChange(val.value);
                onChange && onChange(val.value);
              }}
              {...restProps}
            />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <InputControl.Validation data-testid={`${name}.error`} status='error'>
            {message}
          </InputControl.Validation>
        )}
      />
    </InputControl>
  );
};

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  value: string | number;
  /** Used for aria-label and typeahead search */
  label: string;
};

const StyledOption = styled(Typography)`
  align-items: center;
  display: flex;
  line-height: unset;
  gap: ${grid[8]};
`;

ValidatedSelect.Item = function (props: ItemProps) {
  return (
    <span label={props.label} value={props.value} key={props.value} {...props}>
      {props.children}
    </span>
  );
};

export default ValidatedSelect;
